import clsx from "clsx";
import type IconProps from "components/atoms/icons/Icon";
import { forwardRef } from "react";
import { DEFAULT_ICON_SIZE } from "util/const";

const Settings = forwardRef<SVGSVGElement, IconProps>(
  (
    { size = DEFAULT_ICON_SIZE, left, right, auto, color, className, ...rest },
    ref,
  ): JSX.Element => (
    <svg
      ref={ref}
      className={clsx(
        "icon",
        left && "mr-1",
        right && "ml-1",
        auto && "mx-auto",
        color && `text-${color}`,
        className,
      )}
      viewBox="0 0 24 24"
      fill="currentColor"
      width={size || "1em"}
      height={size || "1em"}
      {...rest}
    >
      <rect fill="none" height="24" width="24" />
      <path
        d="M12.04,8.5c-1.93,0-3.5,1.57-3.5,3.5s1.57,3.5,3.5,3.5s3.5-1.57,3.5-3.5S13.97,8.5,12.04,8.5L12.04,8.5z M17.5,12c0,0.53-0.08,1.04-0.22,1.53l2.14,1.62l-0.98,1.71l-2.47-1.04c-0.71,0.74-1.62,1.28-2.65,1.53L12.98,20h-1.97l-0.33-2.66 c-1.02-0.25-1.94-0.79-2.65-1.53l-2.47,1.04l-0.98-1.71l2.14-1.62c-0.14-0.49-0.22-1-0.22-1.53s0.08-1.04,0.22-1.53L4.58,8.85 l0.98-1.71l2.47,1.04c0.71-0.74,1.62-1.28,2.65-1.53L11.02,4h1.97l0.33,2.66c1.02,0.25,1.94,0.79,2.65,1.53l2.47-1.04l0.98,1.71 l-2.14,1.62C17.42,10.96,17.5,11.47,17.5,12z"
        opacity=".3"
      />
      <path d="M12.04,10.5c0.83,0,1.5,0.67,1.5,1.5s-0.67,1.5-1.5,1.5s-1.5-0.67-1.5-1.5S11.21,10.5,12.04,10.5 M12.04,8.5 c-1.93,0-3.5,1.57-3.5,3.5s1.57,3.5,3.5,3.5s3.5-1.57,3.5-3.5S13.97,8.5,12.04,8.5L12.04,8.5z M19.5,12c0-0.23-0.01-0.45-0.03-0.68 l2.57-1.94l-2.75-4.76l-2.96,1.25c-0.37-0.26-0.76-0.49-1.17-0.68L14.75,2h-5.5l-0.4,3.19c-0.41,0.19-0.8,0.42-1.17,0.68L4.71,4.62 L1.96,9.38l2.57,1.94C4.51,11.55,4.5,11.77,4.5,12s0.01,0.45,0.03,0.68l-2.57,1.94l2.75,4.76l2.96-1.25 c0.37,0.26,0.76,0.49,1.17,0.68L9.25,22h5.5l0.4-3.19c0.41-0.19,0.8-0.42,1.17-0.68l2.96,1.25l2.75-4.76l-2.57-1.94 C19.49,12.45,19.5,12.23,19.5,12z M17.5,12c0,0.53-0.08,1.04-0.22,1.53l2.14,1.62l-0.98,1.71l-2.47-1.04 c-0.71,0.74-1.62,1.28-2.65,1.53L12.98,20h-1.97l-0.33-2.66c-1.02-0.25-1.94-0.79-2.65-1.53l-2.47,1.04l-0.98-1.71l2.14-1.62 c-0.14-0.49-0.22-1-0.22-1.53s0.08-1.04,0.22-1.53L4.58,8.85l0.98-1.71l2.47,1.04c0.71-0.74,1.62-1.28,2.65-1.53L11.02,4h1.97 l0.33,2.66c1.02,0.25,1.94,0.79,2.65,1.53l2.47-1.04l0.98,1.71l-2.14,1.62C17.42,10.96,17.5,11.47,17.5,12z" />
    </svg>
  ),
);

Settings.displayName = "Settings";
export default Settings;
