import clsx from "clsx";
import type IconProps from "components/atoms/icons/Icon";
import { forwardRef } from "react";
import { DEFAULT_ICON_SIZE } from "util/const";

const NotificationsOff = forwardRef<SVGSVGElement, IconProps>(
  (
    { size = DEFAULT_ICON_SIZE, left, right, auto, color, className, ...rest },
    ref,
  ): JSX.Element => (
    <svg
      ref={ref}
      className={clsx(
        "ui-svg-inline icon cursor-pointer",
        left && "mr-1",
        right && "ml-1",
        auto && "mx-auto",
        color && `text-${color}`,
        className,
      )}
      viewBox="0 0 24 24"
      fill="currentColor"
      width={size || "1em"}
      height={size || "1em"}
      {...rest}
    >
      <path d="M4 19v-2h2v-7q0-.825.213-1.625.212-.8.637-1.525l1.5 1.5q-.175.4-.262.812Q8 9.575 8 10v7h6.2L1.4 4.2l1.4-1.4 18.4 18.4-1.4 1.4-3.65-3.6Zm14-3.85-2-2V10q0-1.65-1.175-2.825Q13.65 6 12 6q-.675 0-1.262.213-.588.212-1.088.587L8.2 5.35q.5-.375 1.075-.688Q9.85 4.35 10.5 4.2v-.7q0-.625.438-1.062Q11.375 2 12 2t1.062.438q.438.437.438 1.062v.7q1.95.5 3.225 2.087Q18 7.875 18 10Zm-6.925-1.25ZM12 22q-.825 0-1.412-.587Q10 20.825 10 20h4q0 .825-.587 1.413Q12.825 22 12 22Zm.825-12.025Z" />
    </svg>
  ),
);

NotificationsOff.displayName = "NotificationsOff";
export default NotificationsOff;
