import clsx from "clsx";
import type IconProps from "components/atoms/icons/Icon";
import { forwardRef } from "react";
import { DEFAULT_ICON_SIZE } from "util/const";

const Storefront = forwardRef<SVGSVGElement, IconProps>(
  (
    { size = DEFAULT_ICON_SIZE, left, right, auto, color, className, ...rest },
    ref,
  ): JSX.Element => (
    <svg
      ref={ref}
      className={clsx(
        "icon",
        left && "mr-1",
        right && "ml-1",
        auto && "mx-auto",
        color && `text-${color}`,
        className,
      )}
      viewBox="0 0 24 24"
      fill="currentColor"
      width={size || "1em"}
      height={size || "1em"}
      {...rest}
    >
      <g>
        <g>
          <rect fill="none" height="24" width="24" />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M16.54,11.05c-0.6,0.6-1.4,0.95-2.24,0.95c-0.9,0-1.7-0.36-2.29-0.93 C11.43,11.64,10.65,12,9.78,12c-0.91,0-1.71-0.35-2.31-0.95C6.35,12.19,5.06,11.98,5,11.97V19h14.01v-7.03 C18.95,11.98,17.66,12.19,16.54,11.05z"
            enableBackground="new"
            opacity=".3"
          />
          <path d="M21.9,7.89l-1.05-3.37c-0.22-0.9-1-1.52-1.91-1.52H5.05c-0.9,0-1.69,0.63-1.9,1.52L2.1,7.89C1.64,9.86,2.95,11,3,11.06V19 c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7.94C22.12,9.94,22.09,8.65,21.9,7.89z M13,5h1.96l0.54,3.52C15.59,9.23,15.11,10,14.22,10 C13.55,10,13,9.41,13,8.69V5z M8.49,8.52L9.04,5H11v3.69C11,9.41,10.45,10,9.71,10C8.96,10,8.41,9.3,8.49,8.52z M4.04,8.36L5.05,5 h1.97L6.44,8.86C6.36,9.51,5.84,10,5.23,10C4.3,10,3.88,9.03,4.04,8.36z M19.01,19H5v-7.03c0.06,0.01,1.35,0.22,2.47-0.92 c0.6,0.6,1.4,0.95,2.31,0.95c0.87,0,1.65-0.36,2.23-0.93C12.6,11.64,13.4,12,14.3,12c0.84,0,1.64-0.35,2.24-0.95 c1.12,1.14,2.41,0.93,2.47,0.92V19z M18.77,10c-0.61,0-1.14-0.49-1.21-1.14L16.98,5l1.93-0.01l1.05,3.37 C20.12,9.03,19.71,10,18.77,10z" />
        </g>
      </g>
    </svg>
  ),
);

Storefront.displayName = "Storefront";
export default Storefront;
