import clsx from "clsx";
import type IconProps from "components/atoms/icons/Icon";
import { forwardRef } from "react";
import { DEFAULT_ICON_SIZE } from "util/const";

const VisibilityOff = forwardRef<SVGSVGElement, IconProps>(
  (
    { size = DEFAULT_ICON_SIZE, left, right, auto, color, className, ...rest },
    ref,
  ): JSX.Element => (
    <svg
      ref={ref}
      className={clsx(
        "ui-svg-inline icon cursor-pointer",
        left && "mr-1",
        right && "ml-1",
        auto && "mx-auto",
        color && `text-${color}`,
        className,
      )}
      viewBox="0 96 960 960"
      fill="currentColor"
      width={size || "1em"}
      height={size || "1em"}
      {...rest}
    >
      <path d="M806 996 653 845q-35 14-79.5 22.5T480 876q-148 0-273-84T21 556q17-52 55-103.5t87-99.5L40 233l48-50 763 763-45 50ZM480 723q12 0 24.5-3t20.5-6L320 511q-2 10-4.5 22t-2.5 23q0 71 49 119t118 48Zm300 48L631 622q8-12 12-31t4-35q0-70-48.5-118.5T480 389q-17 0-33 3.5T414 404L286 275q34-14 90.5-26.5T485 236q144 0 268.5 84T939 556q-24 66-66 120t-93 95ZM556 546l-73-72q12-8 29.5-6.5T544 479q13 13 17.5 28.5T556 546Z" />
    </svg>
  ),
);

VisibilityOff.displayName = "VisibilityOff";
export default VisibilityOff;
