import clsx from "clsx";
import type IconProps from "components/atoms/icons/Icon";
import { forwardRef } from "react";
import { DEFAULT_ICON_SIZE } from "util/const";

const ArrowDownward = forwardRef<SVGSVGElement, IconProps>(
  (
    { size = DEFAULT_ICON_SIZE, left, right, auto, color, className, ...rest },
    ref,
  ): JSX.Element => (
    <svg
      ref={ref}
      className={clsx(
        "icon",
        left && "mr-1",
        right && "ml-1",
        auto && "mx-auto",
        color && `text-${color}`,
        className,
      )}
      viewBox="0 0 24 24"
      fill="currentColor"
      width={size || "1em"}
      height={size || "1em"}
      {...rest}
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z" />
    </svg>
  ),
);

ArrowDownward.displayName = "ArrowDownward";
export default ArrowDownward;
