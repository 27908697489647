import clsx from "clsx";
import type IconProps from "components/atoms/icons/Icon";
import { forwardRef } from "react";
import { DEFAULT_ICON_SIZE } from "util/const";

const DisplayOk = forwardRef<SVGSVGElement, IconProps>(
  (
    { size = DEFAULT_ICON_SIZE, left, right, auto, color, className, ...rest },
    ref,
  ): JSX.Element => (
    <svg
      ref={ref}
      className={clsx(
        "ui-svg-inline icon cursor-pointer",
        left && "mr-1",
        right && "ml-1",
        auto && "mx-auto",
        color && `text-${color}`,
        className,
      )}
      viewBox="0 -960 960 960"
      fill="currentColor"
      width={size || "1em"}
      height={size || "1em"}
      {...rest}
    >
      <path d="M140-856.016c-19.15 0-36.101 7.39-50.86 22.149-14.758 14.758-22.148 31.71-22.148 50.86v521.991c0 19.15 7.39 36.101 22.149 50.86 14.758 14.758 31.709 22.148 50.859 22.148h176.016v84.024h327.968v-84.024H820c19.15 0 36.101-7.39 50.86-22.148 14.758-14.759 22.148-31.71 22.148-50.86v-279.57A249.827 249.827 0 0 1 820-486.25v225.234H140v-521.992h331.406a249.827 249.827 0 0 1 36.68-73.008H140z" />
      <path d="M710.036-914.177c-55.423 0-103.233 20-143.476 60-40.244 40-60.391 88.209-60.391 144.61 0 55.86 20.175 103.723 60.508 143.593 40.333 39.87 88.21 59.805 143.633 59.805 55.423 0 103.272-19.95 143.515-59.805 40.244-39.855 60.352-87.754 60.352-143.71 0-56.51-20.175-104.698-60.508-144.61-40.333-39.911-88.21-59.883-143.633-59.883zM691.17-833.16h36.992v158.984H691.17zm18.867 200c5.772 0 11.07 2.15 15.899 6.484 4.828 4.333 7.226 9.796 7.226 16.367 0 6.572-2.325 12.056-6.992 16.485-4.667 4.428-10.13 6.64-16.367 6.64-6.238 0-11.548-2.15-15.977-6.484-4.428-4.333-6.64-9.796-6.64-16.367 0-6.572 2.15-12.056 6.484-16.485 4.333-4.428 9.796-6.64 16.367-6.64z" />
    </svg>
  ),
);

DisplayOk.displayName = "DisplayOk";
export default DisplayOk;
