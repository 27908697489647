import clsx from "clsx";
import type IconProps from "components/atoms/icons/Icon";
import { forwardRef } from "react";
import { DEFAULT_ICON_SIZE } from "util/const";

const OneDrive = forwardRef<SVGSVGElement, IconProps>(
  (
    { size = DEFAULT_ICON_SIZE, left, right, auto, color, className, ...rest },
    ref,
  ): JSX.Element => (
    <svg
      ref={ref}
      className={clsx(
        "ui-svg-inline icon cursor-pointer",
        left && "mr-1",
        right && "ml-1",
        auto && "mx-auto",
        color && `text-${color}`,
        className,
      )}
      viewBox="0 0 24 24"
      fill="currentColor"
      width={size || "1em"}
      height={size || "1em"}
      {...rest}
    >
      <path d="m21.6924215 13.986674c1.3073018.2041603 2.3075785 1.3349499 2.3075785 2.6996001 0 1.0659557-.6107906 1.9888422-1.5011998 2.439178-.0666449.0338054-.1348593.0649546-.2046432.0932062h-13.70010815v-.0004829c-1.73928617-.010866-3.14607239-1.4238096-3.14607239-3.1657519 0-1.7487034 1.4175315-3.1663556 3.16623487-3.1663556.08801469 0 .17482205.0045878.260905.0115904-.00482934-.0804085-.00808914-.1611792-.00808914-.242795 0-2.1886561 1.77429891-3.96295496 3.96295491-3.96295496 1.5376613 0 2.8698343.87640416 3.5268658 2.15629956.5434212-.3564052 1.1929672-.5644289 1.891531-.5644289 1.9075886 0 3.4539427 1.5463541 3.4539427 3.4539427 0 .083789-.0041049.1666121-.0099001.2489523zm-13.50500293-1.7780416c-1.93233896.2130946-3.44029982 1.8555525-3.44029982 3.8437911 0 .8095178.25209145 1.5626531.68129889 2.1853963h-2.14953845c-1.8108811 0-3.27887919-1.4679981-3.27887919-3.2788792 0-1.7308349 1.34134869-3.1474005 3.04103428-3.269462-.05674472-.2570415-.08753175-.5238625-.08753175-.7979274 0-2.03906736 1.65296174-3.69190837 3.69190834-3.69190837.76436351 0 1.47439696.23217044 2.06345549.62998717.83656212-1.67710843 2.56800064-2.82963 4.56951984-2.82963 2.6439419 0 4.8183515 2.01021204 5.0785321 4.58557738-.0362201-.00096587-.0721986-.00265613-.1085394-.00265613-.5685338 0-1.1251151.11530045-1.6404055.33720854-.8692808-1.20467842-2.2616998-1.92907916-3.7779913-1.92907916-2.4210679 0-4.41715417 1.85458662-4.64256353 4.21758177z" />
    </svg>
  ),
);

OneDrive.displayName = "OneDrive";
export default OneDrive;
