import clsx from "clsx";
import type IconProps from "components/atoms/icons/Icon";
import { forwardRef } from "react";
import { DEFAULT_ICON_SIZE } from "util/const";

const AngleRight = forwardRef<SVGSVGElement, IconProps>(
  (
    { size = DEFAULT_ICON_SIZE, left, right, auto, color, className, ...rest },
    ref,
  ): JSX.Element => (
    <svg
      ref={ref}
      aria-label="add-tags"
      className={clsx(
        "icon",
        left && "mr-1",
        right && "ml-1",
        auto && "mx-auto",
        color && `text-${color}`,
        className,
      )}
      viewBox="0 0 24 24"
      fill="currentColor"
      width={size || "1em"}
      height={size || "1em"}
      {...rest}
    >
      <path d="M9.87891,17.24268a1,1,0,0,1-.707-1.707L12.707,12,9.17188,8.46436a.99989.99989,0,0,1,1.41406-1.41407L14.82812,11.293a.99962.99962,0,0,1,0,1.41406l-4.24218,4.24268A.99678.99678,0,0,1,9.87891,17.24268Z" />
    </svg>
  ),
);

AngleRight.displayName = "AngleRight";
export default AngleRight;
