import { DEFAULT_ICON_SIZE } from "util/const";
import type IconProps from "components/atoms/icons/Icon";
import clsx from "clsx";
import { forwardRef } from "react";

const ErrorIcon = forwardRef<SVGSVGElement, IconProps>(
  (
    { size = DEFAULT_ICON_SIZE, left, right, auto, color, className, ...rest },
    ref,
  ): JSX.Element => (
    <svg
      ref={ref}
      className={clsx(
        "icon",
        left && "mr-1",
        right && "ml-1",
        auto && "mx-auto",
        color && `text-${color}`,
        className,
      )}
      viewBox="0 0 24 24"
      fill="currentColor"
      width={size || "1em"}
      height={size || "1em"}
      {...rest}
    >
      <g>
        <rect fill="none" height="24" width="24" />
      </g>
      <g>
        <g>
          <g>
            <path
              d="M12,4c-4.42,0-8,3.58-8,8s3.58,8,8,8s8-3.58,8-8S16.42,4,12,4z M13,17h-2v-2 h2V17z M13,13h-2V7h2V13z"
              enableBackground="new"
              opacity=".3"
            />
            <path d="M11.99,2C6.47,2,2,6.48,2,12s4.47,10,9.99,10C17.52,22,22,17.52,22,12S17.52,2,11.99,2z M12,20c-4.42,0-8-3.58-8-8 s3.58-8,8-8s8,3.58,8,8S16.42,20,12,20z" />
            <rect height="2" width="2" x="11" y="15" />
            <rect height="6" width="2" x="11" y="7" />
          </g>
        </g>
      </g>
    </svg>
  ),
);

ErrorIcon.displayName = "Error";
export default ErrorIcon;
