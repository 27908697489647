import clsx from "clsx";
import type IconProps from "components/atoms/icons/Icon";
import { createElement, forwardRef } from "react";
import { DEFAULT_ICON_SIZE } from "util/const";

const Search = forwardRef<SVGSVGElement, IconProps>(
  ({ size = DEFAULT_ICON_SIZE, left, right, auto, color, className, ...rest }, ref): JSX.Element =>
    createElement(
      "svg",
      {
        ref,
        width: size,
        height: size,
        viewBox: "0 0 24 24",
        fill: "currentColor",
        className: clsx(
          "icon",
          left && "mr-1",
          right && "ml-1",
          auto && "mx-auto",
          color && `text-${color}`,
          className,
        ),
        ...rest,
      },
      createElement("path", {
        d: "M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z",
      }),
    ),
);

Search.displayName = "Search";
export default Search;
