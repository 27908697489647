import clsx from "clsx";
import type IconProps from "components/atoms/icons/Icon";
import { forwardRef } from "react";
import { DEFAULT_ICON_SIZE } from "util/const";

const Tenancy = forwardRef<SVGSVGElement, IconProps>(
  (
    { size = DEFAULT_ICON_SIZE, left, right, auto, color, className, ...rest },
    ref,
  ): JSX.Element => (
    <svg
      ref={ref}
      className={clsx(
        "ui-svg-inline icon cursor-pointer",
        left && "mr-1",
        right && "ml-1",
        auto && "mx-auto",
        color && `text-${color}`,
        className,
      )}
      viewBox="0 0 24 24"
      fill="currentColor"
      width={size || "1em"}
      height={size || "1em"}
      {...rest}
    >
      <path d="M4 21q-1.25 0-2.125-.875T1 18q0-1.25.875-2.125T4 15q.225 0 .438.037.212.038.412.088L8.9 9.55q-.425-.525-.662-1.175Q8 7.725 8 7q0-1.65 1.175-2.825Q10.35 3 12 3q1.65 0 2.825 1.175Q16 5.35 16 7q0 .725-.25 1.375t-.675 1.175l4.075 5.575q.2-.05.412-.088Q19.775 15 20 15q1.25 0 2.125.875T23 18q0 1.25-.875 2.125T20 21q-1.25 0-2.125-.875T17 18q0-.475.138-.913.137-.437.387-.787l-4.05-5.575q-.125.05-.237.075-.113.025-.238.075v4.3q.875.3 1.438 1.075Q15 17.025 15 18q0 1.25-.875 2.125T12 21q-1.25 0-2.125-.875T9 18q0-.975.562-1.738.563-.762 1.438-1.087v-4.3q-.125-.05-.238-.075-.112-.025-.237-.075L6.475 16.3q.25.35.388.787Q7 17.525 7 18q0 1.25-.875 2.125T4 21Zm0-2q.425 0 .713-.288Q5 18.425 5 18t-.287-.712Q4.425 17 4 17t-.712.288Q3 17.575 3 18t.288.712Q3.575 19 4 19Zm8-12Zm0 12q.425 0 .713-.288Q13 18.425 13 18t-.287-.712Q12.425 17 12 17t-.712.288Q11 17.575 11 18t.288.712Q11.575 19 12 19Zm8 0q.425 0 .712-.288Q21 18.425 21 18t-.288-.712Q20.425 17 20 17t-.712.288Q19 17.575 19 18t.288.712Q19.575 19 20 19ZM4 18Zm8 0Zm8 0Zm-8-9q.825 0 1.413-.588Q14 7.825 14 7t-.587-1.412Q12.825 5 12 5q-.825 0-1.412.588Q10 6.175 10 7t.588 1.412Q11.175 9 12 9Z" />
    </svg>
  ),
);

Tenancy.displayName = "Tenancy";
export default Tenancy;
