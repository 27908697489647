import clsx from "clsx";
import type IconProps from "components/atoms/icons/Icon";
import { forwardRef } from "react";
import { DEFAULT_ICON_SIZE } from "util/const";

const Visibility = forwardRef<SVGSVGElement, IconProps>(
  (
    { size = DEFAULT_ICON_SIZE, left, right, auto, color, className, ...rest },
    ref,
  ): JSX.Element => (
    <svg
      ref={ref}
      className={clsx(
        "ui-svg-inline icon cursor-pointer",
        left && "mr-1",
        right && "ml-1",
        auto && "mx-auto",
        color && `text-${color}`,
        className,
      )}
      viewBox="0 96 960 960"
      fill="currentColor"
      width={size || "1em"}
      height={size || "1em"}
      {...rest}
    >
      <path d="M480.294 723Q550 723 598.5 674.206t48.5-118.5Q647 486 598.206 437.5t-118.5-48.5Q410 389 361.5 437.794t-48.5 118.5Q313 626 361.794 674.5t118.5 48.5Zm-.412-71q-39.465 0-67.674-28.326Q384 595.348 384 555.882q0-39.465 28.326-67.674Q440.652 460 480.118 460q39.465 0 67.674 28.326Q576 516.652 576 556.118q0 39.465-28.326 67.674Q519.348 652 479.882 652ZM480 876q-151 0-274.5-89T21 556q61-142 184.5-231T480 236q151 0 274.5 89T939 556q-61 142-184.5 231T480 876Z" />
    </svg>
  ),
);

Visibility.displayName = "Visibility";
export default Visibility;
