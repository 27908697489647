import clsx from "clsx";
import type IconProps from "components/atoms/icons/Icon";
import { forwardRef } from "react";
import { DEFAULT_ICON_SIZE } from "util/const";

const EventBusy = forwardRef<SVGSVGElement, IconProps>(
  (
    { size = DEFAULT_ICON_SIZE, left, right, auto, color, className, ...rest },
    ref,
  ): JSX.Element => (
    <svg
      ref={ref}
      className={clsx(
        "icon",
        left && "mr-1",
        right && "ml-1",
        auto && "mx-auto",
        color && `text-${color}`,
        className,
      )}
      viewBox="0 0 24 24"
      fill="currentColor"
      width={size || "1em"}
      height={size || "1em"}
      {...rest}
    >
      <g>
        <rect fill="none" height="24" width="24" />
      </g>
      <g>
        <g>
          <rect enableBackground="new" height="2" opacity=".3" width="14" x="5" y="6" />
          <path d="M19,4h-1V2h-2v2H8V2H6v2H5C3.9,4,3,4.9,3,6v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V6C21,4.9,20.1,4,19,4z M19,20H5V10h14 V20z M19,8H5V6h14V8z M9.7,18.71l2.3-2.3l2.3,2.3l1.41-1.41l-2.3-2.3l2.3-2.3l-1.41-1.41l-2.3,2.3l-2.3-2.3L8.29,12.7l2.3,2.3 l-2.3,2.3L9.7,18.71z" />
        </g>
      </g>
    </svg>
  ),
);

EventBusy.displayName = "EventBusy";
export default EventBusy;
