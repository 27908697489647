import clsx from "clsx";
import type IconProps from "components/atoms/icons/Icon";
import { forwardRef } from "react";
import { DEFAULT_ICON_SIZE } from "util/const";

const Done = forwardRef<SVGSVGElement, IconProps>(
  (
    { size = DEFAULT_ICON_SIZE, left, right, auto, color, className, ...rest },
    ref,
  ): JSX.Element => (
    <svg
      ref={ref}
      className={clsx(
        "icon",
        left && "mr-1",
        right && "ml-1",
        auto && "mx-auto",
        color && `text-${color}`,
        className,
      )}
      viewBox="0 0 24 24"
      fill="currentColor"
      width={size || "1em"}
      height={size || "1em"}
      {...rest}
    >
      <g>
        <path d="M0,0h24v24H0V0z" fill="none" />
      </g>
      <g>
        <polygon points="18.7,6 9.53,15.17 5.28,10.93 3.87,12.35 9.53,18 20.13,7.4" />
      </g>
    </svg>
  ),
);

Done.displayName = "Done";
export default Done;
