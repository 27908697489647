import clsx from "clsx";
import type IconProps from "components/atoms/icons/Icon";
import { forwardRef } from "react";
import { DEFAULT_ICON_SIZE } from "util/const";

const Lg = forwardRef<SVGSVGElement, IconProps>(
  (
    { size = DEFAULT_ICON_SIZE, left, right, auto, color, className, ...rest },
    ref,
  ): JSX.Element => (
    <svg
      ref={ref}
      className={clsx(
        "icon",
        left && "mr-1",
        right && "ml-1",
        auto && "mx-auto",
        color && `text-${color}`,
        className,
      )}
      fill="currentColor"
      width={size || "1em"}
      height={size || "1em"}
      {...rest}
      viewBox="0 0 78.934 78.932"
    >
      <path
        fill="inherit"
        d="M39.467 78.932c21.796 0 39.467-17.67 39.467-39.466S61.264 0 39.467 0C17.67 0 0 17.67 0 39.466s17.67 39.466 39.467 39.466Z"
      />
      <path fill="#fff" d="M49.077 53.355h-8.173v-30.89h-3.031v33.938h3.031v-.02h8.173z" />
      <path
        fill="#fff"
        d="M69.477 40.73H49.125v-3.031h23.333c.031.586.046 1.173.046 1.767 0 18.247-14.792 33.038-33.038 33.038-18.246 0-33.038-14.791-33.038-33.038 0-18.247 14.792-33.038 33.039-33.038.481 0 .961.01 1.438.031l-.004 3.008a31.023 31.023 0 0 0-1.434-.033c-16.586 0-30.033 13.446-30.033 30.032 0 16.587 13.446 30.032 30.033 30.032 16.134 0 29.296-12.722 30.002-28.683z"
      />
      <path fill="#fff" d="M27.143 31.609a4.643 4.643 0 1 0 0-9.285 4.643 4.643 0 0 0 0 9.285z" />
    </svg>
  ),
);

Lg.displayName = "Lg";
export default Lg;
