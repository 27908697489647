import clsx from "clsx";
import type IconProps from "components/atoms/icons/Icon";
import { forwardRef } from "react";
import { DEFAULT_ICON_SIZE } from "util/const";

const DisplayOk = forwardRef<SVGSVGElement, IconProps>(
  (
    { size = DEFAULT_ICON_SIZE, left, right, auto, color, className, ...rest },
    ref,
  ): JSX.Element => (
    <svg
      ref={ref}
      className={clsx(
        "ui-svg-inline icon cursor-pointer",
        left && "mr-1",
        right && "ml-1",
        auto && "mx-auto",
        color && `text-${color}`,
        className,
      )}
      viewBox="0 -960 960 960"
      fill="currentColor"
      width={size || "1em"}
      height={size || "1em"}
      {...rest}
    >
      <path d="M140-856.016c-19.15 0-36.101 7.39-50.86 22.149-14.758 14.758-22.148 31.71-22.148 50.86v521.991c0 19.15 7.39 36.101 22.149 50.86 14.758 14.758 31.71 22.148 50.859 22.148h176.016v84.024h327.968v-84.024H820c19.15 0 36.101-7.39 50.86-22.148 14.758-14.759 22.148-31.71 22.148-50.86V-527.93L820-455.508v194.492H140v-521.992h276.68l73.632-73.008H140z" />
      <path
        d="M625.411-511.223 507.177-627.1v-166.144l118.234-115.878h169.523L913.17-793.245v166.144L794.934-511.223zm17.817-106.353 66.945-65.612 66.945 65.612 27.534-26.986-66.945-65.61 66.945-65.612-27.534-26.985-66.945 65.61-66.945-65.61-27.534 26.985 66.945 65.611-66.945 65.611zm-1.62 67.727h137.13l95.02-93.125v-134.398l-95.02-93.125h-137.13l-95.02 93.125v134.398zm68.565-160.324z"
        style={{ strokeWidth: 0.534476 }}
      />
    </svg>
  ),
);

DisplayOk.displayName = "DisplayOk";
export default DisplayOk;
