import clsx from "clsx";
import type IconProps from "components/atoms/icons/Icon";
import { forwardRef } from "react";
import { DEFAULT_ICON_SIZE } from "util/const";

const People = forwardRef<SVGSVGElement, IconProps>(
  (
    { size = DEFAULT_ICON_SIZE, left, right, auto, color, className, ...rest },
    ref,
  ): JSX.Element => (
    <svg
      ref={ref}
      className={clsx(
        "icon",
        left && "mr-1",
        right && "ml-1",
        auto && "mx-auto",
        color && `text-${color}`,
        className,
      )}
      viewBox="0 0 24 24"
      fill="currentColor"
      width={size || "1em"}
      height={size || "1em"}
      {...rest}
    >
      <g>
        <rect fill="none" height="24" width="24" />
      </g>
      <g>
        <g>
          <path
            d="M9,10c1.1,0,2-0.9,2-2s-0.9-2-2-2S7,6.9,7,8S7.9,10,9,10z M9,15 c-2.7,0-5.8,1.29-6,2.01V18h12v-1C14.8,16.29,11.7,15,9,15z"
            enableBackground="new"
            opacity=".3"
          />
          <path d="M13.77,11.79C14.16,11.91,14.57,12,15,12c2.21,0,4-1.79,4-4s-1.79-4-4-4c-0.47,0-0.91,0.1-1.33,0.24 C14.5,5.27,15,6.58,15,8s-0.5,2.73-1.33,3.76C13.71,11.77,13.74,11.78,13.77,11.79z M16.66,13.13C18.03,14.06,19,15.32,19,17v3h4 v-3C23,14.82,19.42,13.53,16.66,13.13z M9,13c-2.67,0-8,1.34-8,4v3h16v-3C17,14.34,11.67,13,9,13z M15,18H3v-0.99 C3.2,16.29,6.3,15,9,15s5.8,1.29,6,2V18z M9,12c2.21,0,4-1.79,4-4s-1.79-4-4-4S5,5.79,5,8S6.79,12,9,12z M9,6c1.1,0,2,0.9,2,2 s-0.9,2-2,2S7,9.1,7,8S7.9,6,9,6z" />
        </g>
      </g>
    </svg>
  ),
);

People.displayName = "People";
export default People;
